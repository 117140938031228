import { default as aboutMMAZRDNOR5Meta } from "D:/mycode/ygcz-portal/pages/about.vue?macro=true";
import { default as _91id_93_46htmlpjNEbWcxSVMeta } from "D:/mycode/ygcz-portal/pages/article_detail/[id].html.vue?macro=true";
import { default as _91current_93_46htmly2VJx3lyj8Meta } from "D:/mycode/ygcz-portal/pages/article/[current].html.vue?macro=true";
import { default as indexisNVwoJhKQMeta } from "D:/mycode/ygcz-portal/pages/book/index.vue?macro=true";
import { default as _91id_93_46htmlbdG9fjEZ0hMeta } from "D:/mycode/ygcz-portal/pages/column/[id].html.vue?macro=true";
import { default as indexjL2NsQLG90Meta } from "D:/mycode/ygcz-portal/pages/column/index.vue?macro=true";
import { default as _91id_93_46htmlRvy9jNH7BtMeta } from "D:/mycode/ygcz-portal/pages/files/[id].html.vue?macro=true";
import { default as indexgmYv6db6KBMeta } from "D:/mycode/ygcz-portal/pages/files/index.vue?macro=true";
import { default as indexAVOTLMBdWYMeta } from "D:/mycode/ygcz-portal/pages/index/index.vue?macro=true";
import { default as centerHdXPodev8bMeta } from "D:/mycode/ygcz-portal/pages/user/center.vue?macro=true";
import { default as friendseO66uyDp9qMeta } from "D:/mycode/ygcz-portal/pages/user/friends.vue?macro=true";
import { default as LogindCeTaGhRTSMeta } from "D:/mycode/ygcz-portal/pages/user/Login.vue?macro=true";
import { default as RegisterrsrlaBPSkIMeta } from "D:/mycode/ygcz-portal/pages/user/Register.vue?macro=true";
import { default as vipO3JkOJT9jvMeta } from "D:/mycode/ygcz-portal/pages/user/vip.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("D:/mycode/ygcz-portal/pages/about.vue")
  },
  {
    name: "article_detail-id.html",
    path: "/article_detail/:id().html",
    component: () => import("D:/mycode/ygcz-portal/pages/article_detail/[id].html.vue")
  },
  {
    name: "article-current.html",
    path: "/article/:current().html",
    component: () => import("D:/mycode/ygcz-portal/pages/article/[current].html.vue")
  },
  {
    name: "book",
    path: "/book",
    component: () => import("D:/mycode/ygcz-portal/pages/book/index.vue")
  },
  {
    name: "column-id.html",
    path: "/column/:id().html",
    component: () => import("D:/mycode/ygcz-portal/pages/column/[id].html.vue")
  },
  {
    name: "column",
    path: "/column",
    component: () => import("D:/mycode/ygcz-portal/pages/column/index.vue")
  },
  {
    name: "files-id.html",
    path: "/files/:id().html",
    component: () => import("D:/mycode/ygcz-portal/pages/files/[id].html.vue")
  },
  {
    name: "files",
    path: "/files",
    component: () => import("D:/mycode/ygcz-portal/pages/files/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("D:/mycode/ygcz-portal/pages/index/index.vue")
  },
  {
    name: "user-center",
    path: "/user/center",
    component: () => import("D:/mycode/ygcz-portal/pages/user/center.vue")
  },
  {
    name: "user-friends",
    path: "/user/friends",
    component: () => import("D:/mycode/ygcz-portal/pages/user/friends.vue")
  },
  {
    name: "user-Login",
    path: "/user/Login",
    component: () => import("D:/mycode/ygcz-portal/pages/user/Login.vue")
  },
  {
    name: "user-Register",
    path: "/user/Register",
    component: () => import("D:/mycode/ygcz-portal/pages/user/Register.vue")
  },
  {
    name: "user-vip",
    path: "/user/vip",
    component: () => import("D:/mycode/ygcz-portal/pages/user/vip.vue")
  }
]